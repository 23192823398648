/*

Designed and Coded by HarzemDesign.com

DO NOT MODIFY THIS 'STYLE.CSS' FILE!

This file is generated from STYLE.LESS file, which is written in LessCSS language.
Less is a CSS pre-processor.

USE 'STYLE-CUSTOM.CSS' TO ADD YOUR OWN CSS RULES.

If you want to change 'STYLE.CSS', modify 'STYLE.LESS' file and compile with 'Koala-App' or 'SimpLESS'.
Download SimpLESS software from wearekiss.com/simpless
Download Koala-App software from koala-app.com
Or contact HarzemDesign for changes.

*/
strong,
b {
  font-weight: bold;
}
.bold {
  font-weight: bold;
}
.light {
  font-weight: 300;
}
.normal {
  font-weight: normal;
}
p {
  line-height: 1.2em;
}
.floatleft {
  float: left;
}
.floatright {
  float: right;
}
.rel {
  position: relative;
}
.abs {
  position: absolute;
}
.redback {
  background: Red;
}
.redshine {
  box-shadow: 0 0 10px red;
}
.hidden {
  display: none;
}
.center {
  text-align: center;
}
.clear {
  clear: both;
}
.sizing {
  box-sizing: border-box;
}
.textsmall {
  font-size: 75%;
}
.colorwhite {
  color: #fff;
}
div.clear {
  clear: both;
}
.padded {
  padding: 30px 0;
}
.trans {
  transition: all 0.2s ease;
}
body {
  image-rendering: -webkit-optimize-contrast;
}
.responsive {
  display: none;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
  visibility: hidden;
}
.clearfix:after {
  clear: both;
}
body {
  background: #fff;
  font-size: 16px;
  font-family: "noto sans", tahoma, sans-serif;
  color: #666;
  margin: 0px;
  padding: 0px;
  width: 100%;
  line-height: normal;
}
.body {
  width: 100%;
  margin: auto;
  position: relative;
}
.body:before,
.body:after {
  content: " ";
  display: table;
  visibility: hidden;
}
.body:after {
  clear: both;
}
a:link,
a:visited {
  color: #185a7a;
  text-decoration: none;
}
a:hover {
  color: #2a88b5;
  text-decoration: none;
}
a img {
  border: 0;
}
input {
  font-size: 14px;
  font-family: "noto sans", tahoma, sans-serif;
}
.half {
  width: 50%;
  float: left;
}
/* design */
#page {
  border-top: 70px solid #38aae1;
}
#header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 68px;
  transition: all 0.2s ease;
  font-family: "Titillium Web", arial, helvetica, sans-serif;
  background: #185a7a;
  border-bottom: 2px solid #38aae1;
  z-index: 20000;
}
#header.white {
  background: #fff;
}
#header a.logo {
  display: block;
  float: left;
  margin-top: 16px;
  margin-left: 20px;
  width: 200px;
  transition: all 0.2s ease;
}
#header a.logo img {
  width: 100%;
}
#menu-area {
  float: right;
  position: relative;
}
#header .top-buttons {
  float: right;
  padding: 18px 22px 0 0;
  transition: all 0.2s ease;
}
#header .top-buttons > a {
  float: right;
  color: #fff;
  display: block;
  background-color: #38aae1;
  line-height: 14px;
  font-size: 11px;
  font-weight: bold;
  padding: 10px 24px 10px 16px;
  margin-left: 11px;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.2s ease;
}
#header .top-buttons > a i {
  font-size: 14px;
  margin-right: 18px;
}
#header .top-buttons > a:hover {
  background-color: #1e8fc5;
}
#header .top-buttons a#panelbutton {
  background-color: #009c4e;
}
#header .top-buttons a#panelbutton:hover {
  background-color: #008944;
}
#header .social-icons {
  float: right;
  padding: 19px 20px 0 0;
  transition: all 0.2s ease;
}
#header .social-icons a {
  float: left;
  width: 30px;
  height: 30px;
  display: block;
  margin-left: 10px;
}
#header .social-icons a.twitter {
  background: url(../images/social-tw.png) center 60% no-repeat;
  background-size: auto 15px;
}
#header .social-icons a.facebook {
  background: url(../images/social-fb.png) center 45% no-repeat;
  background-size: auto 15px;
}
#header .social-icons a span {
  display: none;
}
#header .social-icons a:hover {
  box-shadow: 0 2px 0px #38aae1;
}
#menubutton {
  display: none;
  position: relative;
  float: right;
  z-index: 20001;
  margin-top: 0;
  margin-right: 25px;
  width: 40px;
  height: 40px;
  transition: all 0.2s ease;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}
#menubutton a {
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  display: block;
  transition: all 0.2s ease;
}
#menubutton a:after {
  transition: all 0.2s ease;
  color: #d5dee3;
  content: "";
  display: block;
  width: 15px;
  height: 2px;
  border-radius: 3px;
  background: currentColor;
  position: absolute;
  left: 13px;
  top: 15px;
  box-shadow: 0 -4px 0 currentColor, 0 4px 0 currentColor;
}
#menubutton a.clicked:after {
  color: #fff !important;
}
#menu {
  float: right;
  margin-right: 10px;
}
#menu > ul > li.mobilemenu {
  display: none;
}
#menu > ul > li {
  float: left;
  margin-left: 9px;
  position: relative;
}
#menu > ul > li > a {
  display: block;
  font-size: 13px;
  color: #fff;
  padding: 3px 13px 0px 13px;
  line-height: 65px;
  transition: all 0.2s ease;
  text-transform: uppercase;
}
#menu > ul > li:hover > a {
  background: #253030;
}
#menu > ul > li.dropdown > div {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 1000;
  top: 68px;
  right: 0px;
  background: #253030;
  padding: 5px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
  transition: all 0.2s ease;
}
#menu > ul > li.dropdown > div ul {
  float: left;
}
#menu > ul > li.dropdown > div ul > li {
  min-width: 120px;
  text-transform: uppercase;
}
#menu > ul > li.dropdown > div ul > li.title {
  padding: 10px 0 10px 15px;
  border-bottom: 1px solid #666;
  margin-bottom: 5px;
}
#menu > ul > li.dropdown > div ul > li > a {
  display: block;
  padding: 15px 30px 17px 30px;
  font-size: 14px;
  white-space: nowrap;
  color: #fff;
}
#menu > ul > li.dropdown > div ul > li > a:hover {
  background: #185a7a;
  color: #fff;
}
#menu > ul > li.dropdown:hover > div {
  visibility: visible;
  opacity: 1;
}
#header.white #menu > ul > li > a {
  color: #185a7a;
}
#header.white #menu > ul > li:hover > a {
  background: #253030;
  color: #fff;
}
#header.white #menubutton a:after {
  color: #185a7a;
}
#panellist {
  display: none;
  z-index: 20002;
  position: absolute;
  right: 150px;
  top: 60px;
  transition: all 0.2s ease;
}
#panellist:before {
  content: "";
  display: block;
  position: absolute;
  right: 15px;
  top: -10px;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 10px solid #009c4e;
}
#panellist ul {
  padding: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  border-radius: 0px;
}
#panellist ul li a {
  display: block;
  padding: 5px 30px 5px 30px;
  line-height: 40px;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  background-color: #009c4e;
}
#panellist ul li a:hover {
  background-color: #008944;
}
#header.scrolled {
  height: 40px;
}
#header.scrolled a.logo {
  margin-top: 5px;
  width: 170px;
}
#header.scrolled #menu-area .topmenu {
  padding-top: 8px;
}
#header.scrolled #menubutton {
  margin-top: 4px;
}
#header.scrolled #panellist {
  top: 35px;
}
#header.scrolled .social-icons {
  padding-top: 5px;
}
#header.scrolled .top-buttons {
  padding-top: 3px;
}
#header.scrolled #menu > ul > li {
  position: relative;
}
#header.scrolled #menu > ul > li > a {
  line-height: 37px;
}
#header.scrolled #menu > ul > li.dropdown > div {
  top: 40px;
}
.slider-area {
  background-color: #000;
  position: relative;
  z-index: 100;
  /*.slider + .slider {
		display: none;
	}*/
}
.slider-area .slider {
  min-height: 520px;
  background-color: #000000;
  position: relative;
  box-sizing: border-box;
}
.slider-area .slider .body {
  padding: 0 40px 80px;
  box-sizing: border-box;
}
.slider-area .slider.type-1 {
  padding-top: 145px;
  font-family: "Work Sans", verdana, sans-serif;
}
.slider-area .slider.type-1 .title {
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 0.01em;
  line-height: 1em;
}
.slider-area .slider.type-1 .subtitle {
  font-size: 24px;
  margin-bottom: 40px;
  margin-right: 360px;
}
.slider-area .slider.type-1 .text {
  font-size: 20px;
  font-weight: 300;
}
.slider-area .slider.type-1 a.hugebutton {
  display: block;
  position: absolute;
  right: 55px;
  top: 24px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  line-height: 76px;
  border: 2px solid #fff;
  padding: 3px 40px 6px 100px;
  transition: all 0.2s ease;
}
.slider-area .slider.type-1 a.hugebutton i {
  margin-left: 40px;
  font-size: 28px;
  position: relative;
  top: 2px;
}
.slider-area .slider.type-1 a.hugebutton:hover {
  background-color: #fff;
  color: #1e8fc5;
}
.slider-area .bkg-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.6;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.slider-area .bkg-image:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7) 20%, rgba(0, 0, 0, 0.7) 30%, rgba(0, 0, 0, 0) 60%), linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 50%);
}
.slider-buttons {
  z-index: 101;
  height: 0;
  position: relative;
  top: -80px;
}
.slider-buttons .back {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  position: absolute;
}
.slider-buttons .outer {
  width: 50%;
  float: left;
}
.slider-buttons .outer:before,
.slider-buttons .outer:after {
  content: " ";
  display: table;
  visibility: hidden;
}
.slider-buttons .outer:after {
  clear: both;
}
.slider-buttons .inner:before,
.slider-buttons .inner:after {
  content: " ";
  display: table;
  visibility: hidden;
}
.slider-buttons .inner:after {
  clear: both;
}
.slider-buttons .inner .button-strip {
  display: block;
  width: 50%;
  padding: 1px;
  box-sizing: border-box;
  float: left;
}
.slider-buttons .inner a {
  display: block;
  min-height: 78px;
  box-sizing: border-box;
  padding: 20px 0 0 20px;
  box-shadow: inset 0 4px 0 currentColor;
  position: relative;
}
.slider-buttons .inner a strong {
  font-family: "Titillium Web", arial, helvetica, sans-serif;
  font-size: 16px;
  font-weight: normal;
  display: block;
  margin-bottom: 7px;
  color: #fff;
  line-height: 1em;
}
.slider-buttons .inner a > span {
  font-family: "Work Sans", verdana, sans-serif;
  font-weight: 300;
  font-size: 13px;
  color: #fff;
  display: block;
}
.slider-buttons .inner a > span b {
  font-size: 15px;
  font-weight: bold;
}
.slider-buttons .inner a:before {
  position: absolute;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 11px solid currentColor;
  left: 50%;
  top: -7px;
  transform: translate(-16px);
  transition: all 0.2s ease;
}
.slider-buttons .inner a:hover,
.slider-buttons .inner a.active {
  box-shadow: inset 0 6px 0 currentColor;
}
.slider-buttons .inner a:hover:before,
.slider-buttons .inner a.active:before {
  top: -11px;
}
.slider-buttons .outer:nth-child(1) .inner {
  margin-right: 110px;
}
.slider-buttons .outer:nth-child(2) .inner {
  margin-left: 110px;
}
.slider-buttons:before {
  content: "";
  display: block;
  background: url(../images/logo-shape.png) center center no-repeat;
  width: 250px;
  height: 275px;
  background-size: 250px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: -93px;
  z-index: 3;
  pointer-events: none;
}
.hex-area {
  position: relative;
  z-index: 99;
}
.hex-area .hex-animation {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.hex-area .body {
  position: relative;
  z-index: 2;
}
.hex-area.ddos {
  background-color: #0f384c;
}
.intro-title {
  color: #38aae1;
  font-size: 48px;
  text-align: center;
  font-family: "Titillium Web", arial, helvetica, sans-serif;
  font-weight: bold;
  line-height: 70px;
  padding-top: 80px;
}
.intro-title strong {
  font-size: 80px;
  line-height: 70px;
  display: block;
}
.intro-title em {
  color: #185a7a;
}
.intro-text {
  font-size: 20px;
  font-family: "Titillium Web", arial, helvetica, sans-serif;
  font-weight: 400;
  text-align: center;
  color: #185a7a;
  width: 81%;
  margin: 36px auto;
  min-width: 300px;
  line-height: 1.3em;
}
.home-services {
  margin: 18px 20px;
}
.home-services .service {
  margin: 10px 0;
  width: 25%;
  float: left;
  position: relative;
}
.home-services .service .title {
  position: absolute;
  left: 55px;
  top: 10px;
  width: 50%;
  font-size: 18px;
  font-weight: bold;
  font-family: "Work Sans", verdana, sans-serif;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  z-index: 4;
}
.home-services .service .price {
  position: absolute;
  z-index: 4;
  left: 22px;
  top: 48px;
  color: #fff;
}
.home-services .service .price sup {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-family: "noto sans", tahoma, sans-serif;
  font-weight: bold;
  display: block;
}
.home-services .service .price .dollar {
  float: left;
  font-family: "Work Sans", verdana, sans-serif;
  font-size: 36px;
  font-weight: 300;
  line-height: 30px;
  padding-left: 2px;
}
.home-services .service .price .dollar:before {
  content: "$";
  font-size: 32px;
  position: relative;
  top: -3px;
}
.home-services .service .price .dollar em {
  position: relative;
  top: 5px;
  font-size: 48px;
}
.home-services .service .price small {
  float: left;
  font-size: 13px;
  padding: 13px 0 0 13px;
  letter-spacing: 0.08em;
}
.home-services .service .style {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 3;
}
.home-services .service .style > div:nth-child(1) {
  margin: 0 75px;
  height: 43px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background-color: currentColor;
}
.home-services .service .style > div:nth-child(1):before,
.home-services .service .style > div:nth-child(1):after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -75px;
  border-bottom: 43px solid currentColor;
  border-left: 75px solid transparent;
}
.home-services .service .style > div:nth-child(1):after {
  border-bottom: 43px solid rgba(0, 0, 0, 0.7);
}
.home-services .service .style > div:nth-child(2) {
  margin: 0 75px;
  height: 43px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.home-services .service .style > div:nth-child(3) {
  position: absolute;
  left: 0;
  top: 43px;
  right: 0;
  height: 86px;
  background-color: currentColor;
}
.home-services .service .style > div:nth-child(3):before,
.home-services .service .style > div:nth-child(3):after {
  content: "";
  display: block;
  right: 0;
  position: absolute;
  border-left: 75px solid transparent;
  border-right: 75px solid transparent;
}
.home-services .service .style > div:nth-child(3):before {
  border-bottom: 43px solid currentColor;
  top: -43px;
}
.home-services .service .style > div:nth-child(3):after {
  border-top: 43px solid currentColor;
  bottom: -43px;
}
.home-services .service .style > div:nth-child(4) {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.2);
  right: 0;
  top: 43px;
  height: 86px;
  width: 75px;
}
.home-services .service .style > div:nth-child(4):before,
.home-services .service .style > div:nth-child(4):after {
  content: "";
  display: block;
  right: 0;
  position: absolute;
  border-right: 75px solid transparent;
}
.home-services .service .style > div:nth-child(4):before {
  border-bottom: 43px solid rgba(255, 255, 255, 0.2);
  top: -43px;
}
.home-services .service .style > div:nth-child(4):after {
  border-top: 43px solid rgba(255, 255, 255, 0.2);
  bottom: -43px;
}
.home-services .service .style > div:nth-child(5) {
  width: 150px;
  height: 92px;
  position: absolute;
  top: 38px;
  right: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 133px;
}
.home-services .service.service-icon-game .style > div:nth-child(5) {
  background-image: url(../images/service-icon-game.png);
}
.home-services .service.service-icon-voice .style > div:nth-child(5) {
  background-image: url(../images/service-icon-voice.png);
}
.home-services .service.service-icon-dedi .style > div:nth-child(5) {
  background-image: url(../images/service-icon-dedi.png);
}
.home-services .service.service-icon-hosting .style > div:nth-child(5) {
  background-image: url(../images/service-icon-hosting.png);
}
.home-services .content {
  z-index: 2;
  margin-top: 129px;
  background: #fff;
  border: 2px solid currentColor;
  border-top-width: 0;
  position: relative;
}
.home-services .content .text {
  padding: 16px 115px 23px 20px;
  font-family: "noto sans", tahoma, sans-serif;
  font-size: 14px;
  color: #333;
  line-height: 20px;
  z-index: 4;
}
.home-services .content span.button {
  display: block;
  position: absolute;
  right: -2px;
  bottom: -2px;
  font-weight: bold;
  font-size: 18px;
  width: 105px;
  height: 100px;
  background-color: currentColor;
}
.home-services .content span.button i {
  font-size: 28px;
  position: relative;
  top: 2px;
  margin-left: 6px;
}
.home-services .content span.button a {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 50px 0 0 35px;
  color: #fff;
  transition: all 0.2s ease;
}
.home-services .content span.button a:hover {
  background-color: rgba(0, 0, 0, 0);
}
.home-services .content span.button:before {
  position: absolute;
  left: -1px;
  top: -1px;
  z-index: 3;
  content: "";
  display: block;
  border-left: 106px solid #fff;
  border-bottom: 61px solid transparent;
  pointer-events: none;
}
@media only screen and (max-width: 1499px) {
  .home-services .service .style > div:nth-child(1) {
    margin-right: 0;
  }
  .home-services .service .style > div:nth-child(2) {
    margin-right: 0;
  }
  .home-services .service .style > div:nth-child(3) {
    top: 43px;
    height: 66px;
  }
  .home-services .service .style > div:nth-child(3):before,
  .home-services .service .style > div:nth-child(3):after {
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
  }
  .home-services .service .style > div:nth-child(3):before {
    border-bottom: 30px solid currentColor;
    top: -30px;
  }
  .home-services .service .style > div:nth-child(3):after {
    border-top: 30px solid currentColor;
    bottom: -30px;
  }
  .home-services .service .style > div:nth-child(4) {
    width: 50px;
    height: 66px;
  }
  .home-services .service .style > div:nth-child(4):before,
  .home-services .service .style > div:nth-child(4):after {
    border-right: 50px solid transparent;
  }
  .home-services .service .style > div:nth-child(4):before {
    border-bottom: 30px solid rgba(255, 255, 255, 0.2);
    top: -30px;
  }
  .home-services .service .style > div:nth-child(4):after {
    border-top: 30px solid rgba(255, 255, 255, 0.2);
    bottom: -30px;
  }
  .home-services .service .style > div:nth-child(5) {
    width: 100px;
    height: 70px;
    position: absolute;
    top: 38px;
    right: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100px;
  }
  .home-services .service .title {
    left: 35px;
    width: 70%;
  }
  .home-services .service .price {
    top: 46px;
  }
  .home-services .service .price .dollar {
    position: relative;
    top: -3px;
    font-size: 30px;
  }
  .home-services .service .price .dollar em {
    font-size: 36px;
    top: 2px;
  }
  .home-services .service .price .dollar:before {
    font-size: 26px;
  }
  .home-services .service .price small {
    font-size: 12px;
    padding-left: 5px;
    letter-spacing: 0;
  }
  .home-services .content {
    margin-top: 109px;
  }
}
@keyframes bigmapBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 200000px 0;
  }
}
.white-back {
  background: #ffffff;
}
.bigmap {
  background: url(../images/map-negative.png) center center repeat;
  height: 550px;
  background-size: 1500px;
  position: relative;
}
.bigmap-back {
  width: 1500px;
  margin: 40px auto;
  background: linear-gradient(-50deg, #f0f0f0 25%, #fff 45%, #fff 55%, #f0f0f0 75%);
  animation: bigmapBackground 1300s linear infinite;
}
@keyframes mapPulse {
  0% {
    left: 0;
    top: 0;
    box-shadow: 0 0 20px rgba(56, 170, 225, 0.5), 0 0 0 1px #38aae1;
    width: 0;
    height: 0;
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    left: -100px;
    top: -100px;
    box-shadow: 0 0 40px rgba(56, 170, 225, 0.5), 0 0 0 1px #38aae1;
    width: 200px;
    height: 200px;
    opacity: 0;
  }
}
.bigmap-pins {
  position: absolute;
  left: 0;
  top: 0;
}
.bigmap-pins .pin {
  position: absolute;
  width: 0;
  height: 0;
  cursor: pointer;
}
.bigmap-pins .pin pulse {
  display: block;
  border-radius: 200px;
  position: absolute;
  animation: mapPulse 2s linear infinite;
  pointer-events: none;
  z-index: 9;
}
.bigmap-pins .pin:before {
  content: "";
  display: block;
  position: absolute;
  left: -8px;
  top: -8px;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: #38aae1;
}
.bigmap-pins .pin:after {
  content: "";
  display: block;
  position: absolute;
  left: -20px;
  top: -20px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: transparent;
}
.bigmap-pins .pin span {
  position: absolute;
  z-index: 100;
  display: block;
  visibility: hidden;
  opacity: 0;
  left: 23px;
  top: -21px;
  padding: 0 27px 0 25px;
  border-radius: 3px/16px;
  line-height: 38px;
  background-color: #38aae1;
  white-space: nowrap;
  pointer-events: none;
  transition: all 0.2s ease;
  font-family: "Work Sans", verdana, sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #fff;
}
.bigmap-pins .pin span:before {
  content: "";
  display: block;
  position: absolute;
  left: -16px;
  top: 1px;
  width: 0;
  height: 0;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-right: 18px solid #38aae1;
}
.bigmap-pins .pin:hover span {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  left: 33px;
}
.bigmap-description {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
}
.bigmap-description .block {
  position: relative;
  top: 270px;
  max-width: 600px;
  width: 80%;
  margin: auto;
  border: 1px solid #cccccc;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  text-align: center;
}
.bigmap-description h2 {
  font-size: 36px;
  font-family: "Titillium Web", arial, helvetica, sans-serif;
  font-weight: bold;
  color: #38aae1;
  margin-bottom: 10px;
}
.bigmap-description h2 em {
  color: #185a7a;
}
.bigmap-description p {
  font-family: "Work Sans", verdana, sans-serif;
  font-size: 16px;
  color: #185a7a;
  margin: 10px 30px;
}
.bigmap-description a.bigbutton {
  margin: 15px 0 10px;
}
a.bigbutton {
  display: inline-block;
  padding: 0 33px 0px;
  line-height: 50px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  font-family: "Work Sans", verdana, sans-serif;
  transition: all 0.2s ease;
  background-color: #185a7a;
}
a.bigbutton i.fa {
  position: relative;
  left: 15px;
  font-size: 21px;
}
a.bigbutton:hover {
  background-color: #3a8dbe;
}
.gogaming-colors {
  color: #38aae1;
}
.gogaming-colors em {
  color: #185a7a;
}
.home-about {
  margin: 20px 0;
  text-align: center;
}
.home-about h1 {
  font-family: "Titillium Web", arial, helvetica, sans-serif;
  font-weight: bold;
  font-size: 48px;
  letter-spacing: 0.1em;
  color: #333333;
}
.home-about .desc {
  color: #666;
  line-height: 30px;
  font-size: 18px;
  font-family: "Titillium Web", arial, helvetica, sans-serif;
  font-weight: 300;
  max-width: 1200px;
  padding: 0 20px;
  margin: 18px auto;
}
.home-about .bar {
  background-color: #f7f7f7;
  margin: 20px 0;
}
.home-about .home-about-block {
  float: left;
  width: 50%;
  font-family: "noto sans", tahoma, sans-serif;
  text-align: left;
  box-sizing: border-box;
  padding: 30px 50px 35px 20px;
}
.home-about .home-about-block h2 {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  line-height: 35px;
}
.home-about .home-about-block h2 i {
  font-size: 35px;
  margin-right: 15px;
  position: relative;
  top: 3px;
}
.home-about .home-about-block p {
  margin: 10px 0 10px 0;
  line-height: 25px;
  color: #666666;
  font-size: 16px;
}
.home-about .home-about-block + .home-about-block {
  padding-left: 50px;
  padding-right: 20px;
}
.home-about a.bigbutton {
  padding-left: 40px;
  padding-right: 40px;
  margin: 30px 0;
}
.footer-partners {
  margin-top: 40px;
  padding-bottom: 70px;
}
.footer-partners:before,
.footer-partners:after {
  content: " ";
  display: table;
  visibility: hidden;
}
.footer-partners:after {
  clear: both;
}
.footer-partners .partners {
  height: 100px;
  width: 50%;
  float: left;
}
.footer-partners .partners span {
  opacity: 0;
}
.footer-partners .partners-1 {
  background: url(../images/partners-1.png) right center no-repeat;
  background-size: auto 100px;
}
.footer-partners .partners-2 {
  background: url(../images/partners-2.png) left center no-repeat;
  background-size: auto 100px;
}
#footer {
  position: relative;
  background-color: #185a7a;
}
#footer .bar {
  background: linear-gradient(to right, #185a7a, #38aae1, #185a7a);
  height: 44px;
  position: relative;
}
#footer .bar:before {
  position: absolute;
  left: 50%;
  top: 25px;
  transform: translate(-50%, -50%);
  content: "";
  display: block;
  background: url(../images/logo-footer.png) no-repeat;
  width: 220px;
  height: 240px;
  background-size: 220px;
}
#footer a,
#footer a:link,
#footer a:visited {
  color: #fff;
  transition: all 0.2s ease;
}
#footer a:hover {
  color: #9ddfff;
}
.footer-links {
  padding: 25px 0;
  float: left;
}
.footer-links:before,
.footer-links:after {
  content: " ";
  display: table;
  visibility: hidden;
}
.footer-links:after {
  clear: both;
}
.footer-links ul {
  width: 160px;
  margin-left: 20px;
  float: left;
}
.footer-links ul li {
  display: block;
  min-height: 25px;
}
.footer-links ul li a {
  display: block;
  line-height: 15px;
  padding: 5px 0;
  font-size: 12px;
}
.footer-social {
  float: right;
  padding: 60px 0 0 0;
}
.footer-social a {
  display: block;
  float: left;
  width: 200px;
  box-sizing: border-box;
  padding-bottom: 40px;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 165px;
  font-size: 18px;
  padding-left: 15px;
  margin-right: 40px;
  margin-left: 10px;
}
.footer-social a.fb {
  background-image: url(../images/social-fb-large.png);
}
.footer-social a.tw {
  background-image: url(../images/social-tw-large.png);
}
.footer-social a span {
  font-size: 1px;
  color: transparent;
}
.footer-tagline {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translate(-50%);
  font-size: 22px;
  font-family: "Titillium Web", arial, helvetica, sans-serif;
  font-weight: bold;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  color: #9fe0ff;
  line-height: 30px;
}
.footer-tagline strong {
  color: #38aae1;
  font-size: 24px;
  letter-spacing: 0.12em;
  transform: scale(1.25, 1);
  display: block;
}
.footer-tagline strong em {
  color: #fff;
}
#footer .bottom {
  background-color: #16445a;
  padding: 20px 0  70px 0;
}
#footer .bottom .copyright {
  color: #fff;
  font-size: 12px;
  text-align: center;
  margin: 10px;
}
@keyframes scanningBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 200000px 0;
  }
}
.gameservers-header {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}
.gameservers-header:before {
  z-index: 1;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(141, 202, 225, 0.3);
}
.gameservers-header .body {
  position: relative;
  z-index: 2;
  padding: 40px 20px;
  box-sizing: border-box;
}
.gameservers-header .header-box {
  background-color: rgba(0, 0, 0, 0.6);
  position: relative;
  border-left: 3px solid #38aae1;
}
.gameservers-header .header-box:before,
.gameservers-header .header-box:after {
  content: " ";
  display: table;
  visibility: hidden;
}
.gameservers-header .header-box:after {
  clear: both;
}
.gameservers-header .first-part {
  float: left;
  width: 60%;
  box-sizing: border-box;
  padding: 20px 40px 40px;
}
.gameservers-header .title {
  font-size: 48px;
  font-weight: bold;
  font-family: "Titillium Web", arial, helvetica, sans-serif;
  line-height: 1em;
}
.gameservers-header .subtitle {
  font-size: 18px;
  font-family: "Titillium Web", arial, helvetica, sans-serif;
  font-weight: 300;
  line-height: 1.1em;
  margin: 20px 0 0 0;
}
.gameservers-header .second-part {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 40px;
  box-sizing: border-box;
}
.gameservers-header .second-part .search-title {
  font-family: "Work Sans", verdana, sans-serif;
  font-family: "Titillium Web", arial, helvetica, sans-serif;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
}
.gameservers-header .second-part input {
  display: block;
  background-color: rgba(24, 90, 122, 0.5);
  background: linear-gradient(-50deg, rgba(24, 90, 122, 0.6) 25%, rgba(40, 144, 196, 0.8) 50%, rgba(24, 90, 122, 0.6) 75%);
  animation: scanningBackground 1300s linear infinite;
  font-size: 18px;
  font-family: "Titillium Web", arial, helvetica, sans-serif;
  color: #fff;
  padding: 10px 20px;
  border: 1px solid #000;
  margin: 10px 0;
  width: 85%;
  transition: all 0.2s ease;
}
.gameservers-header .second-part input:focus {
  box-shadow: 0 0 20px rgba(48, 186, 255, 0.5);
  border: 1px solid #fff;
}
.popular-games-back {
  background-color: #10181f;
}
.popular-games-list:before,
.popular-games-list:after {
  content: " ";
  display: table;
  visibility: hidden;
}
.popular-games-list:after {
  clear: both;
}
.popular-games-list .popular-game {
  width: 23%;
  float: left;
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  margin: 50px 1%;
  padding: 10px 0;
  transition: box-shadow 0.3s ease, margin 0.3s ease, padding 0.3s ease;
}
.popular-games-list .popular-game .arrows > div:first-child {
  position: absolute;
  top: -35px;
  border: 0px solid transparent;
  border-bottom: 35px solid rgba(255, 255, 255, 0.1);
}
.popular-games-list .popular-game .arrows > div + div {
  position: absolute;
  bottom: -35px;
  border: 0px solid transparent;
  border-top: 35px solid rgba(255, 255, 255, 0.1);
}
.popular-games-list .popular-game .image {
  width: 90%;
  padding-top: 50%;
  margin: 15px 5%;
  background-size: cover;
}
.popular-games-list .popular-game .flair {
  position: absolute;
  left: -10px;
  top: -10px;
  transform: rotate(-15deg);
  font-size: 13px;
  font-weight: bold;
  font-family: "Work Sans", verdana, sans-serif;
  transition: all 0.2s ease;
  background: currentColor;
}
.popular-games-list .popular-game .flair span {
  display: block;
  color: #fff;
  line-height: 16px;
  padding: 5px 25px;
  white-space: nowrap;
}
.popular-games-list .popular-game .flair:before,
.popular-games-list .popular-game .flair:after {
  position: absolute;
  top: 0;
  content: "";
  display: block;
  border-top: 13px solid transparent;
  border-bottom: 13px solid transparent;
}
.popular-games-list .popular-game .flair:before {
  left: -10px;
  border-right: 10px solid currentColor;
}
.popular-games-list .popular-game .flair:after {
  right: -10px;
  border-left: 10px solid currentColor;
}
.popular-games-list .popular-game:hover {
  box-shadow: 0 0 20px #fff;
  padding: 20px 0;
  margin: 40px 1%;
}
.popular-games-list .popular-game:hover .flair {
  transform: rotate(-5deg) scale(1.2) translate(50px);
}
.popular-games-list .popular-game .title {
  margin: 15px 0 5px 0;
  padding: 10px;
  background-color: #000;
  width: 100%;
  position: relative;
  left: -10px;
  text-align: center;
  font-family: "Titillium Web", arial, helvetica, sans-serif;
  text-transform: uppercase;
  line-height: 1.5em;
}
.popular-games-list .popular-game .title strong {
  color: #30baff;
  display: block;
  font-size: 24px;
}
.popular-games-list .popular-game .title span {
  color: #fff;
  font-size: 20px;
}
.popular-games-list .popular-game .price {
  text-align: center;
  margin: 15px 0 25px;
  font-family: "Work Sans", verdana, sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #fff;
}
.popular-games-list .popular-game .price strong {
  color: #3fc1ff;
  font-size: 26px;
  font-weight: 300;
  position: relative;
  top: 2px;
  margin: 0 5px;
}
.popular-games-list .popular-game a.orderbutton {
  margin: 10px 20px;
  padding-left: 0;
  padding-right: 0;
}
a.orderbutton {
  padding: 10px 15px;
  display: block;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  font-family: "Work Sans", verdana, sans-serif;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.2s ease;
}
a.orderbutton i {
  margin-right: 10px;
}
a.orderbutton.info {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: transparent;
}
a.orderbutton.info:hover {
  background-color: #fff;
  color: #000;
}
a.orderbutton.infodark {
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: #fff;
  color: #000;
}
a.orderbutton.infodark:hover {
  background-color: #3d525b;
  color: #fff;
}
a.orderbutton.cart {
  border: 1px solid transparent;
  background-color: #38AAE1;
}
a.orderbutton.cart:hover {
  background-color: #fff;
  color: #008de1;
}
a.orderbutton.cartdark {
  border: 1px solid transparent;
  background-color: #185a7a;
}
a.orderbutton.cartdark:hover {
  background-color: #38AAE1;
  color: #fff;
}
.game-list-back {
  background-color: #c4c6ca;
}
.game-list-back .game-list {
  padding: 40px 20px;
}
/*
.game-list {
	.clearfix;
	.game {
			background: linear-gradient(to right, #fff 20%,  fade(#fff, 0%) 80%, #fff), #fff;
		&:hover {
			background: linear-gradient(to right, #fff 20%,  fade(#fff, 0%) 80%, #fff), #bae3ff;
		}
		.trans;
		box-shadow: 3px 0 6px fade(#000, 30%);
		
		width:48%;
		float:left;
		margin: 7px 1%;
		
		min-height: 106px;
		
		.rel;
		padding: 10px 10px 10px 90px;
		.sizing;
		
		&:before, &:after {
			content:"";
			.abs;
			z-index:4;
			left:-10px;
			display: block;
			border-left: 82px solid #c4c6ca;
		}
		&:before {
			border-bottom: 38px solid transparent;
			top: -11px;
		}
		&:after {
			border-top: 38px solid transparent;
			bottom: -11px;
		}
		
		height: 86px;
		.image {
			.abs;
			left: 9px;
			top:10px;
			
			width: 80px;
			height: 86px;
			background-size: cover;
			
			border-left: 1px solid #008de1;
			border-right: 1px solid #008de1;
			
			background-color: #000000;
			
			&:before, &:after {
				content:"";
				.abs;
				z-index:2;
				left:-1px;
				display: block;
				border-left: 41px solid #008de1;
				border-right: 41px solid #008de1;
			}
			&:before {
				border-bottom: 20px solid transparent;
				border-top: 1px solid #008de1;
				top: 0px;
			}
			&:after {
				border-top: 20px solid transparent;
				border-bottom: 1px solid #008de1;
				bottom: 0px;
			}
			
			> div {
				&:before, &:after {
					content:"";
					.abs;
					z-index:3;
					left:-3px;
					display: block;
					border-left: 43px solid #fff;
					border-right: 43px solid #fff;
					width: 0px;
				}
				&:before {
					border-bottom: 22px solid transparent;
					top: -1px;
				}
				&:after {
					border-top: 22px solid transparent;
					bottom: -1px;
				}
			}
			
		}
		.title {
			float: left;
			padding: 8px 10px 8px 20px;
			margin: 19px 0 0 0;
			min-width: 70%;
			
			//background: linear-gradient(to right, #d4ecff, fade(#d4ecff, 0));
			
			.titil;
			.f20;
			text-shadow: 0 1px 1px #fff;
			
		}
		.price {
			.abs;
			right:0;
			top:0;
			padding: 10px 20px;
			color: #000;
			
			text-transform: uppercase;
			.f13;
			.bold;
			.work;
			
			strong {
				.f18;
				color: #32ab00;
				
			}
			
		}
		
		.buttonset {
			
			.abs;
			right:0;
			bottom:0;
			padding: 0px;
			
			a {
				float: left;
				margin: 0 0 0 0;
			}
		}
		
	}
}
*/
.game-list:before,
.game-list:after {
  content: " ";
  display: table;
  visibility: hidden;
}
.game-list:after {
  clear: both;
}
.game-list .game {
  background: linear-gradient(to right, #fff 20%, rgba(255, 255, 255, 0) 80%, #fff), #fff;
  box-shadow: 3px 0 6px rgba(0, 0, 0, 0.3);
  width: 98%;
  margin: 8px 1%;
  min-height: 50px;
  position: relative;
  padding: 0px 10px 10px 50px;
  box-sizing: border-box;
}
.game-list .game:before,
.game-list .game:after {
  display: block;
  content: "";
  position: absolute;
  z-index: 4;
  left: -2px;
  border-left: 34px solid #c4c6ca;
}
.game-list .game:before {
  border-bottom: 17px solid transparent;
  top: -4px;
}
.game-list .game:after {
  border-top: 17px solid transparent;
  bottom: -4px;
}
.game-list .game .image {
  position: absolute;
  left: 4px;
  top: 4px;
  width: 40px;
  height: 43px;
  background-size: cover;
  border-left: 1px solid #008de1;
  border-right: 1px solid #008de1;
  background-color: #000000;
}
.game-list .game .image:before,
.game-list .game .image:after {
  content: "";
  position: absolute;
  z-index: 2;
  left: -1px;
  display: block;
  border-left: 21px solid #008de1;
  border-right: 21px solid #008de1;
}
.game-list .game .image:before {
  border-bottom: 10px solid transparent;
  border-top: 1px solid #008de1;
  top: 0px;
}
.game-list .game .image:after {
  border-top: 10px solid transparent;
  border-bottom: 1px solid #008de1;
  bottom: 0px;
}
.game-list .game .image > div:before,
.game-list .game .image > div:after {
  content: "";
  position: absolute;
  z-index: 3;
  left: -2px;
  display: block;
  border-left: 22px solid #fff;
  border-right: 22px solid #fff;
  width: 0px;
}
.game-list .game .image > div:before {
  border-bottom: 11px solid transparent;
  top: 0px;
}
.game-list .game .image > div:after {
  border-top: 11px solid transparent;
  bottom: 0px;
}
.game-list .game .title {
  float: left;
  padding: 8px 10px 8px 20px;
  margin: 0 0 0 0;
  min-width: 70%;
  font-family: "Titillium Web", arial, helvetica, sans-serif;
  font-size: 20px;
  text-shadow: 0 1px 1px #fff;
}
.game-list .game .price {
  position: absolute;
  right: 290px;
  top: 0;
  padding: 14px 20px 0 0;
  color: #000;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  font-family: "Work Sans", verdana, sans-serif;
}
.game-list .game .price strong {
  font-size: 18px;
  color: #32ab00;
}
.game-list .game .buttonset {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 0px;
}
.game-list .game .buttonset a {
  float: left;
  margin: 0 0 0 0;
}
.game-list .game a.more-button {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
}
.game-list .game a.more-button:before {
  position: absolute;
  left: 17px;
  top: 10px;
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  border-right: 3px solid #666;
  border-bottom: 3px solid #666;
  transform: rotate(45deg);
}
.game-list .game a.more-button:hover {
  background-color: #eee;
}
.nogames {
  font-size: 16px;
  font-family: "Work Sans", verdana, sans-serif;
  text-align: center;
  padding: 20px 10px;
  color: #930;
  margin-bottom: 50px;
  border: 1px solid #960;
  background-color: rgba(255, 255, 0, 0.1);
}
.big-heading {
  padding: 70px 0;
  font-family: "Work Sans", verdana, sans-serif;
  text-align: center;
}
.big-heading.lowpadding {
  padding: 20px 0;
}
.big-heading .title {
  font-size: 64px;
  color: #185a7a;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 0.8;
}
.big-heading .subtitle {
  font-size: 18px;
  font-weight: 300;
  color: #38aae1;
  font-family: "Work Sans", verdana, sans-serif;
}
.big-heading .supertitle {
  color: #38aae1;
  font-size: 28px;
  font-weight: bold;
}
.big-heading.left {
  text-align: left;
}
.big-heading.purple .title,
.big-heading.purple .subtitle,
.big-heading.purple .supertitle {
  color: #7a007a;
}
.ddos-header-back {
  background: #000;
}
.ddos-header {
  background: linear-gradient(to right, #06080b 30%, rgba(24, 90, 122, 0) 90%), url(../images/hex-grill.jpg) repeat;
  background-size: contain,
			50%;
  overflow: hidden;
}
.ddos-header .big-heading {
  float: left;
  width: 50%;
  color: #fff;
}
.ddos-header .big-heading .title {
  color: #fff;
}
.ddos-header .big-heading .subtitle {
  color: #fff;
}
.ddos-header .big-heading .container {
  padding-left: 20px;
}
.ddos-header .body {
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
}
.ddos-header .corero {
  background: url(../images/corero.png) no-repeat;
  background-size: contain;
  position: absolute;
  right: 10%;
  top: 10%;
  width: 36%;
  padding-top: 75%;
  transform: rotateY(-60deg) rotateX(10deg);
  animation: corero3d 30s ease-in-out infinite;
}
@keyframes corero3d {
  0% {
    transform: rotateY(-60deg) rotateX(10deg);
  }
  30% {
    transform: rotateY(-40deg) rotateX(8deg) translateZ(-50px);
  }
  50% {
    transform: rotateY(-45deg) rotateX(3deg) translateZ(-40px) translateY(10px);
  }
  80% {
    transform: rotateY(-40deg) rotateX(8deg) translateZ(-30px);
  }
  100% {
    transform: rotateY(-60deg) rotateX(10deg);
  }
}
.ddos-feature-bar .feature {
  padding: 10px 0 20px;
  box-sizing: border-box;
  text-align: center;
  width: 46%;
  margin: 20px 2%;
  float: left;
  color: #fff;
}
.ddos-feature-bar .feature .icon {
  margin: 10px auto;
  height: 180px;
  max-width: 500px;
  border-radius: 100%;
  box-shadow: 10px -8px 2px -10px #185a7a, -10px 8px 2px -10px #38aae1;
  background-size: 220px;
  background-position: center center;
  background-repeat: no-repeat;
  transition: all 0.2s ease;
}
.ddos-feature-bar .feature:hover .icon {
  box-shadow: 20px 5px 2px -10px #185a7a, -20px -5px 2px -10px #38aae1;
  background-size: 260px;
}
.ddos-feature-bar .feature .title {
  color: #185a7a;
  font-family: "Work Sans", verdana, sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin: 40px 0 20px;
}
.ddos-feature-bar .feature .text {
  font-size: 16px;
  font-weight: 300;
  font-family: "Work Sans", verdana, sans-serif;
  line-height: 1.5em;
  color: #000;
  width: 90%;
  margin: auto;
}
.ddos-armor {
  background: #01001f url(../images/assets/armor.jpg) right center no-repeat;
  background-size: contain;
  margin: 20px 0 0 0;
  min-height: 500px;
}
.ddos-armor .body {
  position: relative;
}
.ddos-armor .ddos-graph {
  margin-bottom: 40px;
  transform-origin: 0 0;
}
.ddos-armor .title-area {
  padding: 30px 0 0 10%;
}
.ddos-armor .title-area .title {
  font-family: "Titillium Web", arial, helvetica, sans-serif;
  transform: scale(1.25, 1);
  transform-origin: 0 50%;
  letter-spacing: 0.1em;
  font-size: 48px;
  font-weight: bold;
  color: #fff;
  max-width: 70%;
}
.ddos-armor .title-area .subtitle {
  font-family: "Work Sans", verdana, sans-serif;
  font-size: 22px;
  font-weight: 300;
  color: #38aae1;
  max-width: 50%;
}
.ddos-graph {
  float: left;
  width: 0;
  height: 450px;
  position: relative;
}
.ddos-graph > div {
  position: absolute;
  z-index: 10;
}
.ddos-graph > div.ani {
  position: relative;
  left: 0;
  top: 0;
  z-index: 9;
}
.ddos-graph .icon1 {
  left: 300px;
  top: 150px;
  background: url(../images/ddos-icon-1.png) center center no-repeat;
  background-size: 150px;
  width: 150px;
  height: 160px;
}
.ddos-graph .icon2 {
  left: 650px;
  top: 148px;
  background: url(../images/ddos-icon-2.png) center center no-repeat;
  background-size: 150px;
  width: 150px;
  height: 150px;
}
.ddos-graph .text1 {
  width: 150px;
  left: 300px;
  top: 320px;
  text-align: center;
  font-family: "Work Sans", verdana, sans-serif;
  color: #b3ff14;
  font-weight: bold;
  font-size: 16px;
}
.ddos-graph .text1 span {
  display: block;
  font-size: 20px;
}
.ddos-graph .text2 {
  width: 150px;
  left: 480px;
  top: 260px;
  text-align: center;
  font-family: "Work Sans", verdana, sans-serif;
  color: #fff;
  letter-spacing: 0.1em;
  font-size: 16px;
}
.ddos-graph .text3 {
  width: 150px;
  left: 650px;
  top: 310px;
  text-align: center;
  font-family: "Work Sans", verdana, sans-serif;
  color: #85c7e7;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
}
.ddos-graph .text3 span {
  display: block;
  font-size: 20px;
}
.ddos-graph .lines {
  position: absolute;
  left: 400px;
  top: 190px;
  width: 300px;
  border-top: 3px dotted rgba(255, 255, 255, 0.5);
  animation: ddosAniLines 1s linear infinite;
}
@keyframes ddosAniLines {
  0% {
    left: 400px;
  }
  100% {
    left: 412px;
  }
}
.ddos-graph .lines + .lines {
  top: 255px;
  animation: ddosAniLines 1s linear reverse infinite;
}
.ddos-graph .good-guys {
  position: absolute;
  top: 210px;
  height: 25px;
  width: 360px;
  background: url(../images/ddos-icon-good.png) center center repeat-x;
  background-size: 40px;
  animation: ddosAniGoodguys 1.2s linear infinite;
}
@keyframes ddosAniGoodguys {
  0% {
    left: 360px;
  }
  100% {
    left: 400px;
  }
}
.ddos-graph .attack .guide {
  position: absolute;
  left: -20px;
  top: 220px;
  width: 420px;
  border-top: 3px dotted rgba(255, 255, 255, 0.5);
  transform: rotate(-25deg);
  transform-origin: 120% 50%;
}
.ddos-graph .attack .guide + .guide {
  transform: rotate(25deg);
}
.ddos-graph .attack .good,
.ddos-graph .attack .bad {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 360px;
  top: 200px;
}
.ddos-graph .attack .good {
  background: url(../images/ddos-icon-neutr.png) center center no-repeat;
  background-size: 30px;
  animation: ddosAniAttack1 30s linear infinite;
}
.ddos-graph .attack .bad {
  background: url(../images/ddos-icon-bad.png) center center no-repeat;
  background-size: 40px;
  animation: ddosAniAttack1 18s linear infinite;
}
.ddos-graph .attack .good + .good {
  animation-delay: -10.5s;
  transform: translate(0, 40px);
}
.ddos-graph .attack .good + .good + .good {
  animation-delay: -17.5s;
  transform: translate(0, -40px);
}
.ddos-graph .attack .bad {
  animation-delay: -5s;
  transform: translate(0, 0);
  animation-duration: 18s;
}
.ddos-graph .attack .bad + .bad {
  animation-delay: -9.5s;
  transform: translate(0, -20px);
  animation-duration: 15s;
}
.ddos-graph .attack .bad + .bad + .bad {
  animation-delay: -14.5s;
  transform: translate(0, 20px);
  animation-duration: 24s;
}
.ddos-graph .attack .bad.small {
  background-size: 30px;
}
.ddos-graph .attack .bad + .bad.small {
  animation-delay: -9s;
  transform: translate(0, 0);
  animation-duration: 20s;
}
.ddos-graph .attack .bad.small + .bad.small {
  animation-delay: -12s;
  transform: translate(0, -20px);
  animation-duration: 15s;
}
.ddos-graph .attack .bad.small + .bad.small + .bad.small {
  animation-delay: -13s;
  transform: translate(0, 20px);
  animation-duration: 25s;
}
@keyframes ddosAniAttack1 {
  0% {
    left: 0px;
    top: 40px;
  }
  10% {
    left: 330px;
    top: 200px;
  }
  10.001% {
    left: 0px;
    top: 300px;
  }
  20% {
    left: 330px;
    top: 200px;
  }
  20.001% {
    left: 0px;
    top: 100px;
  }
  30% {
    left: 330px;
    top: 200px;
  }
  30.001% {
    left: 0px;
    top: 90px;
  }
  40% {
    left: 330px;
    top: 200px;
  }
  40.001% {
    left: 0px;
    top: 320px;
  }
  50% {
    left: 330px;
    top: 200px;
  }
  50.001% {
    left: 0px;
    top: 60px;
  }
  60% {
    left: 330px;
    top: 200px;
  }
  60.001% {
    left: 0px;
    top: 360px;
  }
  70% {
    left: 330px;
    top: 200px;
  }
  70.001% {
    left: 0px;
    top: 140px;
  }
  80% {
    left: 330px;
    top: 200px;
  }
  80.001% {
    left: 0px;
    top: 280px;
  }
  90% {
    left: 330px;
    top: 200px;
  }
  90.001% {
    left: 0px;
    top: 160px;
  }
  100% {
    left: 330px;
    top: 200px;
  }
}
.three-boxes:before,
.three-boxes:after {
  content: " ";
  display: table;
  visibility: hidden;
}
.three-boxes:after {
  clear: both;
}
.three-boxes .box {
  float: left;
  width: 30%;
  margin: 20px 1.6665%;
}
.three-boxes .box .title {
  font-family: "Work Sans", verdana, sans-serif;
  font-weight: bold;
  color: #000;
  font-size: 18px;
}
.three-boxes .box .text {
  color: #999;
  margin: 20px 0;
}
.three-boxes .box .icon {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100px;
  height: 100px;
  margin: 10px 0 20px 0;
}
.game-header {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.game-header:before {
  z-index: 1;
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(141, 202, 225, 0.3));
}
.game-header .body {
  position: relative;
  z-index: 2;
  padding: 40px 20px;
  box-sizing: border-box;
}
.game-header .game-name-box {
  text-align: center;
  padding: 100px 20px;
}
.game-header .game-name-box .title {
  color: #fff;
  font-family: "Work Sans", verdana, sans-serif;
  font-size: 64px;
  font-weight: bold;
}
.game-header .game-name-box .subtitle {
  font-family: "Work Sans", verdana, sans-serif;
  font-size: 20px;
  color: #fff;
  margin: 30px auto 0 auto;
  max-width: 1200px;
}
.game-pricing-bar {
  background-color: #10181f;
  border-top: 10px solid #000;
  border-bottom: 10px solid #000;
}
.game-pricing-bar .bar-content {
  margin: auto;
  width: 90%;
}
.game-pricing-bar .box:nth-child(1) {
  background-color: #283036;
}
.game-pricing-bar .box:nth-child(2) {
  background-color: #40464c;
}
.game-pricing-bar .box:nth-child(3) {
  background: #000;
}
.game-pricing-bar .box {
  transform: skew(-30deg);
}
.game-pricing-bar .box .content {
  transform: skew(30deg);
}
.game-pricing-bar .box {
  float: left;
  width: 33.33%;
  padding: 20px 0;
  text-align: center;
  box-sizing: border-box;
  color: #fff;
}
.game-pricing-bar .box span {
  color: #fff;
  font-size: 16px;
  font-family: "Titillium Web", arial, helvetica, sans-serif;
  line-height: 1.2em;
}
.game-pricing-bar .box strong {
  line-height: 1.2em;
  display: block;
  font-size: 48px;
  font-weight: 300;
  font-family: "Work Sans", verdana, sans-serif;
  color: #51c212;
}
.game-pricing-bar a.big-order-button {
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-top: 20px;
  padding: 3px 30px 6px 50px;
  background: linear-gradient(-60deg, #329acb 25%, #39c7ff 50%, #329acb 75%), #fff;
  animation: scanningBackground 1500s linear infinite;
  transition: all 0.2s ease;
  transform: skew(-30deg);
}
.game-pricing-bar a.big-order-button i {
  margin-left: 20px;
  font-size: 28px;
  position: relative;
  top: 2px;
}
.game-pricing-bar a.big-order-button:hover {
  background: #fff;
  color: #1e8fc5;
}
.game-pricing-bar a.big-order-button em {
  display: inline-block;
  transform: skew(30deg);
  line-height: 50px;
}
.half-size-map .map-part {
  width: 50%;
  float: left;
  overflow: hidden;
}
.half-size-map .map-part .white-back {
  transform: scale(0.5);
  transform-origin: 0 0;
}
.half-size-map .map-part .bigmap-back {
  background: #d4d0cc !important;
}
.half-size-map .content-part {
  width: 50%;
  float: left;
  min-height: 500px;
  border-left: 1px solid #67b6e4;
  box-sizing: border-box;
}
.faq-list {
  padding: 20px 20px;
}
.faq-list .question {
  font-family: "Work Sans", verdana, sans-serif;
  font-size: 18px;
  color: #38aae1;
  font-weight: bold;
}
.faq-list .answer {
  margin: 10px 20px 40px 20px;
  color: #185a7a;
}
.dedicated-header-back {
  background: #000;
}
.dedicated-header {
  background: linear-gradient(to right, #06080b 30%, rgba(24, 90, 122, 0) 90%), url(../images/hex-grill.jpg) repeat;
  background-size: contain,
	50%;
}
.dedicated-header .big-heading {
  color: #fff;
}
.dedicated-header .big-heading .title {
  color: #fff;
}
.dedicated-header .big-heading .subtitle {
  color: #fff;
  width: 90%;
  max-width: 1200px;
  margin: auto;
}
.option-bar {
  border-bottom: 2px solid #ccc;
  margin: 0 0 30px 0;
  padding-top: 30px;
  background-color: #eee;
  text-align: center;
}
.option-bar a {
  line-height: 80px;
  padding: 0 40px 2px 40px;
  font-family: "Work Sans", verdana, sans-serif;
  font-size: 18px;
  font-weight: normal;
  color: #666;
  display: inline-block;
  text-transform: uppercase;
}
.option-bar a:hover {
  background-color: #fff;
}
.option-bar a.selected {
  background: #ffffff;
  position: relative;
  top: 2px;
  border-top: 2px solid #ccc;
  border-left: 2px solid #ccc;
  border-right: 2px solid #ccc;
  color: #38aae1;
  font-weight: bold;
}
.server-type {
  font-family: "Work Sans", verdana, sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  margin: 20px 20px;
  text-align: center;
}
.server-table {
  width: 96%;
  margin: 20px auto 40px auto;
  max-width: 1200px;
}
.server-table .th:before,
.server-table .th:after {
  content: " ";
  display: table;
  visibility: hidden;
}
.server-table .th:after {
  clear: both;
}
.server-table .th > div {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  text-align: left;
  padding-left: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #38aae1;
  background: #f4f4f4;
}
.server-table .th > div:first-child {
  border-left: 1px solid #ccc;
  border-radius: 5px 0 0 0;
  padding-left: 20px;
}
.server-table .th > div:last-child {
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
}
.server-table .server:before,
.server-table .server:after {
  content: " ";
  display: table;
  visibility: hidden;
}
.server-table .server:after {
  clear: both;
}
.server-table .server > div {
  line-height: 30px;
  min-height: 30px;
}
.server-table .server .order {
  padding: 7px 10px;
}
.server-table .server .order a {
  border: 1px solid #185a7a;
  background-color: #185a7a;
  display: inline-block;
  padding: 0px 20px;
  border-radius: 5px;
  line-height: 34px;
  color: #fff;
  font-size: 14px;
  font-family: "Work Sans", verdana, sans-serif;
  font-weight: bold;
}
.server-table .server .order a:hover {
  background-color: #38aae1;
}
.server-table > div {
  box-sizing: border-box;
}
.server-table > div > div:first-child {
  border-left: 1px solid #ccc;
}
.server-table > div > div:last-child {
  border-right: 1px solid #ccc;
}
.server-table > div > div {
  box-sizing: border-box;
  float: left;
  padding: 10px 10px;
  border-bottom: 1px solid #ccc;
  color: #185a7a;
  font-family: "Work Sans", verdana, sans-serif;
  line-height: 25px;
  white-space: nowrap;
  overflow: hidden;
}
.server-table > div > div > span,
.server-table > div > div > a {
  line-height: 25px;
}
.server-table > div > div.proc {
  padding-left: 60px;
  background: url(../images/icon-intel.png) 10px 13px no-repeat;
  background-size: auto 25px;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
.server-table > div > div.price {
  color: #185a7a;
  font-size: 13px;
}
.server-table > div > div.price span {
  font-weight: bold;
  font-size: 16px;
}
.server-table > div > div:nth-child(1) {
  width: 27%;
}
.server-table > div > div:nth-child(2) {
  width: 15%;
}
.server-table > div > div:nth-child(3) {
  width: 8%;
}
.server-table > div > div:nth-child(4) {
  width: 15%;
}
.server-table > div > div:nth-child(5) {
  width: 10%;
}
.server-table > div > div:nth-child(6) {
  width: 10%;
}
.server-table > div > div:nth-child(7) {
  width: 15%;
}
.text-page {
  margin: 40px auto;
  max-width: 900px;
  padding: 10px 10px;
  font-family: "Titillium Web", arial, helvetica, sans-serif;
}
.text-page h1 {
  font-weight: 300;
  font-size: 48px;
  color: #185a7a;
  margin: 20px 0;
}
.text-page h2 {
  font-weight: 300;
  font-size: 32px;
  color: #185a7a;
  margin: 20px 0;
}
.text-page h3 {
  font-weight: normal;
  font-size: 24px;
  color: #185a7a;
  margin: 20px 0;
}
.text-page h4,
.text-page h5,
.text-page h6 {
  font-weight: normal;
  font-size: 18px;
  color: #185a7a;
  margin: 10px 0;
}
.text-page p {
  margin: 20px 0;
  padding: 0 20px;
  font-weight: 300;
  line-height: 1.5em;
}
.text-page ul {
  padding-left: 40px;
}
.web-hosting-boxes {
  text-align: center;
}
.web-hosting-boxes .box {
  width: 23%;
  min-width: 260px;
  display: inline-block;
  margin: 10px 0.5%;
  padding-bottom: 25px;
  border: 1px solid #ccc;
  color: #000;
}
.web-hosting-boxes .box .title {
  font-family: "Work Sans", verdana, sans-serif;
  padding: 25px 0 35px 0;
  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: normal;
  color: #666;
  letter-spacing: 0.1em;
  background-color: #eee;
}
.web-hosting-boxes .box .title:before,
.web-hosting-boxes .box .title:after {
  content: "";
  display: inline-block;
  max-width: 50px;
  width: 100%;
  border-bottom: 1px solid #999;
  position: relative;
  top: -5px;
}
.web-hosting-boxes .box .price {
  padding: 12px 0 8px 0;
  background-color: currentColor;
  box-shadow: -7px 0 0 currentColor, 7px 0 0 currentColor;
  position: relative;
}
.web-hosting-boxes .box .price:before,
.web-hosting-boxes .box .price:after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  width: 0;
  height: 0;
  transform: translate(-50%, 0);
  border-left: 42px solid transparent;
  border-right: 42px solid transparent;
}
.web-hosting-boxes .box .price:before {
  top: -20px;
  border-bottom: 22px solid currentColor;
}
.web-hosting-boxes .box .price:after {
  bottom: -20px;
  border-top: 22px solid currentColor;
}
.web-hosting-boxes .box .price > div {
  color: #fff;
  font-family: "Work Sans", verdana, sans-serif;
  font-size: 16px;
}
.web-hosting-boxes .box .price > div em {
  font-weight: bold;
  font-size: 24px;
  display: block;
  line-height: 15px;
}
.web-hosting-boxes .box .price > div em sup {
  font-size: 70%;
  position: relative;
  top: -2px;
}
.web-hosting-boxes .box ul {
  margin: 30px 10px;
}
.web-hosting-boxes .box ul li.intel {
  background: url(../images/icon-intel.png) center 0 no-repeat;
  background-size: 30px;
  margin: 20px 0;
  border-bottom: 1px solid #ccc;
  padding-top: 25px;
  font-weight: bold;
  font-size: 14px;
}
.web-hosting-boxes .box ul li.intel b {
  color: #38aae1;
}
.web-hosting-boxes .box ul li {
  padding: 0 0 10px 0;
  font-size: 16px;
}
.web-hosting-boxes .box ul li em {
  font-weight: bold;
  font-size: 18px;
}
.web-hosting-boxes .box ul li.small {
  padding: 5px 0;
  font-size: 13px;
  color: #666;
}
.web-hosting-boxes .box ul li.small em {
  font-size: 13px;
  color: #333;
}
.web-hosting-boxes .box:nth-child(1) .price {
  color: #7a007a;
}
.web-hosting-boxes .box:nth-child(2) .price {
  color: #8b118b;
}
.web-hosting-boxes .box:nth-child(3) .price {
  color: #9c229c;
}
.web-hosting-boxes .box:nth-child(4) .price {
  color: #ad33ad;
}
.web-hosting-boxes .box:nth-child(5) .price {
  color: #be44be;
}
.web-hosting-boxes .box:nth-child(6) .price {
  color: #cf55cf;
}
.web-hosting-boxes .box:nth-child(7) .price {
  color: #e066e0;
}
.web-hosting-boxes a.button {
  background-color: #7a007a;
  padding: 0 30px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
  line-height: 40px;
  display: inline-block;
  transition: all 0.2s ease;
}
.web-hosting-boxes a.button:hover {
  background-color: #be33ad;
}
.web-hosting-features-area {
  background: #01001f url(../images/assets/lineback.png) center center no-repeat;
  background-size: cover;
  min-height: 500px;
  margin: 0 0 50px 0;
}
.web-hosting-features {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 70px 0 90px 0;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
.web-hosting-features .feature {
  margin: 0 auto;
  position: relative;
  padding: 10px 0;
  min-height: 170px;
  box-sizing: border-box;
  color: #fff;
}
.web-hosting-features .feature .icon {
  position: absolute;
  left: 100px;
  top: 0px;
  width: 150px;
  height: 174px;
  /*
			border: 1px solid #fff;
			border-radius: 100%;
			*/
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center center;
}
.web-hosting-features .feature .icon:before {
  position: absolute;
  content: "";
  width: 100px;
  left: -100px;
  top: 0;
  height: 87px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.web-hosting-features .feature .icon:after {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: url(../images/icon-hexagon.png) center center no-repeat;
  background-size: contain;
}
.web-hosting-features .feature .content {
  padding-left: 300px;
  padding-top: 20px;
}
.web-hosting-features .feature .title {
  font-family: "Work Sans", verdana, sans-serif;
  font-weight: bold;
  font-size: 24px;
  color: #9bd0f5;
}
.web-hosting-features .feature .title strong {
  color: #00ff7f;
}
.web-hosting-features .feature .title em {
  color: #ff9900;
}
.web-hosting-features .feature .text {
  margin: 10px;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6em;
  font-size: 14px;
}
.web-hosting-features .feature:nth-child(odd) .icon {
  left: 135px;
}
.web-hosting-features .feature:nth-child(odd) .icon:before {
  width: 135px;
  left: -135px;
}
.web-hosting-features .feature:nth-child(odd) .content {
  padding-left: 315px;
}
.web-hosting-features .feature:nth-child(even) .icon {
  left: 60px;
}
.web-hosting-features .feature:nth-child(even) .icon:before {
  width: 60px;
  left: -60px;
}
.web-hosting-features .feature:nth-child(even) .content {
  padding-left: 315px;
}
.domain-area {
  padding: 0px 0 30px 0;
  margin-top: 50px;
  background-color: #0e7172;
}
.domain-area .title {
  font-family: "Work Sans", verdana, sans-serif;
  font-size: 24px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  padding: 30px 0;
}
.domain-area .domain-form {
  margin: 0 auto 40px;
  max-width: 600px;
  padding: 0 10px;
}
.domain-area .domain-form input[type=text] {
  width: 80%;
  float: left;
  border: 1px solid #00ff7f;
  border-radius: 5px;
  padding: 10px 20px;
  box-sizing: border-box;
  line-height: 30px;
  font-size: 24px;
  font-family: "noto sans", tahoma, sans-serif;
  background-color: #1ce2e4;
  transition: all 0.2s ease;
}
.domain-area .domain-form input[type=text]:focus {
  background-color: #fff;
}
.domain-area .domain-form input[type=submit] {
  line-height: 30px;
  font-size: 24px;
  font-family: "Work Sans", verdana, sans-serif;
  font-weight: bold;
  margin-left: 2%;
  width: 18%;
  color: #fff;
  background-color: #00ff7f;
  border: 1px solid #00ff7f;
  border-radius: 5px;
  padding: 10px 0;
  cursor: pointer;
  transition: all 0.2s ease;
}
.domain-area .domain-form input[type=submit]:hover {
  background-color: #000;
}
.domain-pricing {
  text-align: center;
  margin-bottom: 15px;
}
.domain-pricing .domain {
  text-align: center;
  display: inline-block;
  color: #fff;
  margin: 10px 5px;
  padding: 5px 20px 10px;
  border: 1px solid rgba(28, 226, 228, 0.5);
  border-radius: 10px;
}
.domain-pricing .domain strong {
  font-size: 16px;
  font-weight: bold;
  padding: 5px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: block;
}
.domain-pricing .domain span {
  padding: 5px 0;
  display: block;
  font-size: 13px;
}
.about-header {
  position: relative;
  background-color: #7d3717;
  margin-bottom: 40px;
}
.about-header > div {
  position: relative;
  z-index: 2;
}
.about-header:before {
  z-index: 1;
  position: absolute;
  content: "";
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: url(../images/assets/aboutback.jpg) center center no-repeat;
  background-size: cover;
  opacity: 0.3;
}
.about-header .big-heading .title {
  font-family: "Titillium Web", arial, helvetica, sans-serif;
}
.about-header .big-heading .title,
.about-header .big-heading .subtitle {
  color: #fff;
}
.about-header .about-blocks {
  margin-bottom: 40px;
}
.about-header .about-blocks:before,
.about-header .about-blocks:after {
  content: " ";
  display: table;
  visibility: hidden;
}
.about-header .about-blocks:after {
  clear: both;
}
.about-header .about-block {
  float: left;
  width: 30%;
  margin: 15px 1.665%;
  box-sizing: border-box;
  padding: 20px 30px 20px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.3);
  background-color: rgba(125, 55, 23, 0.3);
  color: #fff;
  border-radius: 10px;
}
.about-header .about-block .title {
  text-align: center;
  font-family: "Work Sans", verdana, sans-serif;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 20px;
  color: #fff;
}
.about-header .about-block .text {
  text-align: center;
  font-family: "Work Sans", verdana, sans-serif;
  font-weight: 300;
}
.about-header .about-block .icon-draw {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100px;
  height: 100px;
  margin-bottom: 15px;
}
.icon-draw.icd-1 {
  background-image: url(../images/icon-draw-1.png);
}
.icon-draw.icd-2 {
  background-image: url(../images/icon-draw-2.png);
}
.icon-draw.icd-3 {
  background-image: url(../images/icon-draw-3.png);
}
.aff-header {
  position: relative;
  background-color: #3f5c38;
  margin-bottom: 40px;
}
.aff-header > div {
  position: relative;
  z-index: 2;
}
.aff-header:before {
  z-index: 1;
  position: absolute;
  content: "";
  display: block;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: url(../images/assets/affback.jpg) center center no-repeat;
  background-size: cover;
  opacity: 0.2;
}
.aff-header .big-heading .title {
  font-family: "Titillium Web", arial, helvetica, sans-serif;
}
.aff-header .big-heading .title,
.aff-header .big-heading .subtitle {
  color: #fff;
}
.aff-header ul.aff-blocks {
  text-align: center;
  margin-bottom: 40px;
}
.aff-header ul.aff-blocks:before,
.aff-header ul.aff-blocks:after {
  content: " ";
  display: table;
  visibility: hidden;
}
.aff-header ul.aff-blocks:after {
  clear: both;
}
.aff-header ul.aff-blocks li {
  display: block;
  float: left;
  width: 19%;
  margin: 10px 0.5%;
  box-sizing: border-box;
  padding: 10px 10px 30px;
  color: #fff;
  font-family: "Work Sans", verdana, sans-serif;
  background-color: #183d0b;
  background: linear-gradient(to bottom, #317d17, #317d17 60px, #183d0b 61px);
  border-radius: 5px;
  box-shadow: 0 -50px 50px -50px #aeff96, 0 50px 50px -50px #000000;
}
.aff-header ul.aff-blocks li i.fa {
  color: #fff;
  font-size: 28px;
  display: block;
  text-align: center;
  margin: 10px 0 40px;
}
@media all and (max-width: 1900px) {
  .design {
    display: none;
  }
}
@media all and (min-width: 1500px) {
  body {
    min-width: 1500px;
  }
  .body {
    width: 1500px;
  }
}
@media only screen and (max-width: 1499px) {
  body {
    min-width: 320px;
  }
  .slider-area .slider {
    min-height: 460px;
  }
  .slider-area .slider.type-1 {
    padding-top: 120px;
  }
  .slider-area .slider.type-1 .title {
    font-size: 52px;
  }
  .slider-area .slider.type-1 .subtitle {
    font-size: 22px;
    margin-right: 300px;
  }
  .slider-area .slider.type-1 a.hugebutton {
    top: 30px;
    right: 20px;
    line-height: 56px;
    padding: 3px 30px 6px 70px;
  }
  .slider-area .slider.type-1 a.hugebutton i {
    margin-left: 30px;
  }
  .bigmap-back {
    width: 1200px;
  }
  .bigmap {
    height: 440px;
    background-size: 1200px;
  }
  .bigmap-pins {
    transform: scale(0.8);
  }
  .bigmap-pins .pin {
    transform: scale(1.25);
  }
  .bigmap-description .block {
    transform: translate(-100px);
  }
  /*
	.game-list {
		.game {
			.title {
				margin-top: 12px;
			}
		}
	}
	*/
  .ddos-header .big-heading {
    width: 60%;
  }
  .ddos-header .corero {
    width: 30%;
    top: 20%;
  }
  .big-heading .title {
    font-size: 48px;
  }
  .big-heading .supertitle {
    font-size: 24px;
  }
  .ddos-armor {
    background-position: 120% bottom;
    background-size: 600px;
  }
  .ddos-armor .title-area {
    text-align: center;
    padding: 30px 0 0 0;
  }
  .ddos-armor .title-area .title {
    transform-origin: 50% 50%;
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
  .ddos-armor .title-area .subtitle {
    margin: auto;
  }
  .game-header .game-name-box {
    padding: 70px 20px;
  }
  .game-header .game-name-box .title {
    font-size: 48px;
  }
  .game-header .game-name-box .subtitle {
    font-size: 18px;
    margin: 30px auto 0 auto;
  }
}
@media only screen and (max-width: 1300px) {
  #menu > ul > li {
    margin-left: 0;
  }
  #menu > ul > li > a {
    padding-left: 10px;
    padding-right: 10px;
  }
  .footer-partners .partners-1,
  .footer-partners .partners-2 {
    background-size: auto 75px;
  }
  .footer-links ul {
    width: auto;
    margin-right: 10px;
  }
  .footer-social {
    padding-top: 30px;
  }
  .footer-social a {
    margin-right: 20px;
    margin-left: 0px;
    width: 140px;
    background-size: 132px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 1199px) {
  .slider-area .slider {
    min-height: 400px;
  }
  .slider-area .slider.type-1 {
    padding-top: 90px;
  }
  .slider-area .slider.type-1 .title {
    font-size: 42px;
  }
  .slider-area .slider.type-1 .subtitle {
    font-size: 20px;
    margin-right: 250px;
  }
  .slider-area .slider.type-1 a.hugebutton {
    top: 30px;
    right: 20px;
    line-height: 46px;
    padding: 3px 20px 6px 40px;
  }
  .slider-area .slider.type-1 a.hugebutton i {
    margin-left: 20px;
  }
  .slider-buttons .inner a {
    padding: 18px 0 0 12px;
  }
  .slider-buttons .inner a strong {
    margin-bottom: 4px;
  }
  .slider-buttons .inner a > span {
    font-size: 12px;
  }
  .slider-buttons .inner a > span b {
    font-size: 13px;
  }
  .slider-buttons .outer:nth-child(1) .inner {
    margin-right: 73px;
  }
  .slider-buttons .outer:nth-child(2) .inner {
    margin-left: 73px;
  }
  .slider-buttons:before {
    width: 146px;
    height: 183px;
    background-size: 166px;
    top: -48px;
  }
  .home-services .service {
    width: 50%;
  }
  .bigmap-back {
    width: 900px;
    margin-bottom: 200px;
  }
  .bigmap {
    height: 330px;
    background-size: 900px;
  }
  .bigmap-pins {
    transform: scale(0.6);
  }
  .bigmap-pins .pin {
    transform: scale(1.25);
  }
  .bigmap-pins .pin span {
    font-size: 18px;
  }
  .bigmap-description .block {
    transform: translate(-140px);
  }
  .home-about h1 {
    font-size: 36px;
  }
  .home-about .desc {
    line-height: 24px;
    font-size: 17px;
  }
  /*
	.game-list {
		.game {
			width: 98%;
			
			.title {
				margin-top: 19px;
			}
		}
	}
	*/
  .game-pricing-bar .bar-content {
    margin: auto;
    width: 82%;
  }
  .game-pricing-bar .box:nth-child(1) {
    width: 25%;
  }
  .game-pricing-bar .box:nth-child(2) {
    width: 25%;
  }
  .game-pricing-bar .box:nth-child(3) {
    width: 50%;
  }
  .game-pricing-bar .box {
    padding: 10px 0;
  }
  .game-pricing-bar .box span {
    font-size: 14px;
  }
  .game-pricing-bar .box strong {
    font-size: 32px;
    font-weight: normal;
  }
  .game-pricing-bar .box a.big-order-button {
    font-size: 16px;
    margin-top: 20px;
    padding: 3px 10px 6px 30px;
  }
  .game-pricing-bar .box a.big-order-button i {
    margin-left: 10px;
  }
  .game-pricing-bar .box a.big-order-button em {
    line-height: 30px;
  }
}
@media only screen and (max-width: 1199px) and (min-width: 960px) {
  #header .top-buttons {
    padding: 4px 10px 0 0;
  }
  #header .top-buttons > a {
    padding: 0;
    width: 60px;
    height: 60px;
    margin-left: 2px;
    text-align: center;
  }
  #header .top-buttons > a i {
    display: block;
    margin: auto;
    padding: 4px 0;
    background-color: rgba(0, 0, 0, 0.2);
    margin-bottom: 4px;
  }
  #header .top-buttons .social-icons {
    padding-right: 5px;
  }
  #header .top-buttons .social-icons a:first-child {
    margin-left: 5px;
  }
  #menu {
    margin-right: 0;
  }
  #panellist {
    right: 70px;
    top: 70px;
  }
  #header.scrolled .top-buttons a {
    height: 40px;
  }
  #header.scrolled .top-buttons a span {
    display: none;
  }
}
@media only screen and (max-width: 1120px) {
  .ddos-armor {
    min-height: 500px;
  }
  .ddos-armor .ddos-graph {
    position: absolute;
    transform: scale(0.75);
    top: 100px;
  }
}
@media only screen and (max-width: 1080px) {
  #header a.logo {
    margin-top: 18px;
    width: 170px;
  }
  .intro-title {
    font-size: 36px;
    line-height: 45px;
    padding-top: 50px;
  }
  .intro-title strong {
    font-size: 56px;
    line-height: 45px;
  }
  .popular-games-list .popular-game .title {
    padding: 5px;
    left: -5px;
  }
  .popular-games-list .popular-game .title strong {
    font-size: 20px;
  }
  .popular-games-list .popular-game .title span {
    font-size: 16px;
  }
  .game-list .game {
    padding-left: 40px;
  }
  .game-list .game .price {
    right: 270px;
  }
}
@media only screen and (max-width: 970px) {
  body.noscroll {
    overflow: hidden;
  }
  #page {
    border-top-width: 40px;
  }
  #header {
    height: 40px;
  }
  #header a.logo {
    margin-top: 5px;
    width: 170px;
  }
  #header #menu-area .topmenu {
    padding-top: 8px;
  }
  #header #menubutton {
    margin-top: 4px;
  }
  #header #panellist {
    top: 35px;
  }
  #header .social-icons {
    padding-top: 5px;
  }
  #header .top-buttons {
    padding-top: 3px;
  }
  #header #menu > ul > li {
    position: relative;
  }
  #header #menu > ul > li > a {
    line-height: 37px;
  }
  #header #menu > ul > li.dropdown > div {
    top: 40px;
  }
  body {
    -webkit-text-size-adjust: none;
  }
  .responsive {
    display: block;
  }
  #menu {
    clear: both;
    float: right;
    position: absolute;
    right: 0;
    top: 50px;
    display: none;
    z-index: 20000;
  }
  #menubutton {
    display: block;
  }
  #panelbutton {
    display: none;
  }
  #menu-area {
    width: 100%;
    max-width: 400px;
    overflow: hidden;
    transition: all 0.4s ease;
    position: fixed;
    right: -300px;
    top: 0px;
    bottom: 0;
    background: rgba(14, 52, 71, 0.9);
  }
  #menu-area.visible {
    right: 0;
    display: block;
  }
  #menu-area .top-buttons {
    display: none;
  }
  #menu-area .social-icons {
    float: left;
  }
  #menu-area .social-icons a {
    background-size: auto 25px !important;
  }
  #menu {
    display: block;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    right: 0;
    left: 0;
    top: 50px;
    bottom: 0;
    font-family: "noto sans", tahoma, sans-serif;
  }
  #menu > ul {
    width: 100%;
    padding: 10px 10px 20px;
    font-weight: 300;
  }
  #menu > ul > li.mobilemenu {
    display: block;
  }
  #menu > ul > li {
    clear: both;
    float: none;
    margin: 0;
  }
  #menu > ul > li > a {
    padding-left: 20px;
    margin: 0;
    position: relative;
    color: #fff !important;
    line-height: 37px;
  }
  #menu > ul > li > a:hover {
    color: #00acff;
    background: transparent !important;
  }
  #menu > ul > li:hover > a {
    background: transparent !important;
  }
  #menu > ul > li.dropdown:after,
  #menu > ul > li.dropdown:hover:after,
  #menu > ul > li.dropdown:before,
  #menu > ul > li.dropdown:hover:before {
    display: none;
  }
  #menu > ul > li.dropdown > div {
    visibility: visible;
    float: none;
    display: block;
    opacity: 1;
    width: auto;
    box-sizing: border-box;
    background: transparent;
    padding-bottom: 10px;
    position: relative;
    top: 0 !important;
    right: 0;
    box-shadow: none;
  }
  #menu > ul > li.dropdown > div ul {
    float: none;
    width: 100%;
    border: none;
    padding-bottom: 10px;
  }
  #menu > ul > li.dropdown > div ul > li > a {
    padding: 5px 20px 5px 50px;
    line-height: 30px;
    font-size: 13px;
    background: transparent;
    position: relative;
  }
  #menu > ul > li.dropdown > div ul > li > a:hover {
    background: transparent;
    color: #fff;
  }
  #menu > ul > li.dropdown > div ul > li > a:before {
    position: absolute;
    content: "";
    display: block;
    width: 14px;
    height: 39px;
    left: 30px;
    top: -20px;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  #menu > ul > li.dropdown > div ul > li:first-child > a:before {
    height: 24px;
    top: -5px;
  }
  #menu > ul > li.dropdown.multicolumn ul li.title {
    display: none;
  }
  #menu > ul > li:last-child {
    display: block;
  }
  .intro-title {
    font-size: 32px;
    line-height: 45px;
  }
  .intro-title strong {
    font-size: 52px;
    line-height: 45px;
  }
  .home-services {
    margin: 5px 10px;
  }
  .intro-text {
    margin: 15px auto;
  }
  a.bigbutton {
    font-size: 15px;
    line-height: 36px;
    padding: 0 24px 2px;
  }
  a.bigbutton i.fa {
    left: 10px;
    top: 1px;
  }
  .footer-partners .partners {
    height: 50px;
  }
  .footer-partners .partners-1,
  .footer-partners .partners-2 {
    background-size: auto 50px;
  }
  .gameservers-header .body {
    padding: 25px 10px;
  }
  .gameservers-header .first-part {
    padding: 15px 20px 20px;
  }
  .gameservers-header .title {
    font-size: 32px;
  }
  .gameservers-header .subtitle {
    font-size: 16px;
    margin-top: 15px;
  }
  .gameservers-header .second-part {
    padding: 20px;
  }
  .gameservers-header .second-part .search-title {
    font-size: 14px;
  }
  .gameservers-header .second-part input {
    padding: 5px 10px;
    font-size: 16px;
    width: 85%;
  }
  .ddos-armor {
    min-height: 400px;
    background-size: 400px;
    background-position: 120% 100%;
  }
  .ddos-armor .ddos-graph {
    position: absolute;
    transform: scale(0.66);
    top: 100px;
  }
}
@media only screen and (max-width: 930px) {
  .bigmap-back {
    width: 570px;
    margin-bottom: 0;
  }
  .bigmap {
    height: 209px;
    background-size: 570px;
  }
  .bigmap-pins {
    transform: scale(0.38);
  }
  .bigmap-pins .pin {
    transform: scale(1.25);
  }
  .bigmap-pins .pin span {
    font-size: 24px;
  }
  .bigmap-description {
    position: relative;
    height: auto;
    padding-bottom: 30px;
  }
  .bigmap-description .block {
    transform: none;
    top: 0;
  }
  .footer-links {
    margin-top: 170px;
    float: none;
    width: 100%;
    text-align: center;
  }
  .footer-links ul {
    display: inline-block;
    text-align: left;
    float: none;
    margin: 0 30px;
    vertical-align: top;
  }
  .footer-links ul li a {
    font-size: 16px;
    padding: 12px 0;
  }
  .footer-social {
    padding-bottom: 30px;
    float: none;
    text-align: center;
  }
  .footer-social a {
    margin: 10px 20px 10px 0;
    width: 140px;
    float: none;
    display: inline-block;
    background-size: 132px;
    font-size: 16px;
  }
  .game-list .game .title {
    font-size: 18px;
    margin-top: 2px;
  }
  .game-list .game .title strong {
    font-weight: normal;
  }
  .game-list .game .price {
    right: 40px;
  }
  .game-list .game .buttonset {
    display: none;
  }
  .game-list .game a.more-button {
    display: block;
  }
  .game-list .game.activated {
    margin-bottom: 60px;
  }
  .game-list .game.activated .buttonset {
    display: block;
    bottom: -40px;
  }
  .game-header .game-name-box {
    padding: 50px 20px;
  }
  .game-header .game-name-box .title {
    font-size: 36px;
  }
  .game-header .game-name-box .subtitle {
    font-size: 18px;
    margin: 20px auto 0 auto;
  }
  .half-size-map .map-part .white-back {
    transform: scale(1);
  }
  .half-size-map .map-part,
  .half-size-map .content-part {
    width: 100%;
    float: none;
  }
  .server-table {
    text-align: center;
  }
  .server-table .th {
    display: none;
  }
  .server-table > div {
    width: 290px;
    display: inline-block;
    border: 1px solid #ccc;
  }
  .server-table > div > div {
    width: 100% !important;
    border: none !important;
    text-align: center;
    padding: 10px 0 !important;
    line-height: 10px !important;
  }
  .server-table > div > div.proc {
    background-position: center 10px;
    padding-top: 40px !important;
  }
  .option-bar {
    margin: 0 0 30px 0;
    padding-top: 10px;
  }
  .option-bar a {
    line-height: 50px;
    padding: 0 20px 2px 20px;
  }
}
@media only screen and (max-width: 920px) {
  .slider-area .slider {
    min-height: 350px;
  }
  .slider-area .slider.type-1 {
    padding-top: 60px;
  }
  .slider-area .slider.type-1 .title {
    font-size: 36px;
  }
  .slider-area .slider.type-1 .subtitle {
    margin-right: 200px;
    margin-bottom: 20px;
    margin-top: 15px;
    font-size: 18px;
  }
  .slider-area .slider.type-1 a.hugebutton {
    top: 50px;
    line-height: 36px;
    padding: 3px 15px 6px 25px;
    font-size: 16px;
  }
  .slider-area .slider.type-1 a.hugebutton i {
    margin-left: 10px;
    font-size: 21px;
  }
  .home-about h1 {
    font-size: 32px;
    letter-spacing: 0.05em;
  }
  .about-header .about-block {
    width: 97%;
    margin: 15px 1.5%;
  }
  .aff-header ul.aff-blocks li {
    width: 90%;
    margin: 10px 5%;
    text-align: left;
    background: #183d0b;
    padding: 0;
    line-height: 16px;
    font-size: 14px;
  }
  .aff-header ul.aff-blocks li > div {
    padding: 10px 10px 0 10px;
  }
  .aff-header ul.aff-blocks li i.fa {
    display: block;
    float: left;
    margin: 0px 10px 0px 0px;
    padding-top: 10px;
    width: 50px;
    height: 40px;
    background-color: #317d17;
  }
}
@media only screen and (max-width: 800px) {
  .slider-area .slider {
    min-height: 260px;
  }
  .slider-area .slider .body {
    padding: 0 20px 80px;
  }
  .slider-area .slider.type-1 {
    padding-top: 20px;
  }
  .slider-area .slider.type-1 .title {
    font-size: 28px;
  }
  .slider-area .slider.type-1 .subtitle {
    font-size: 16px;
  }
  .slider-area .slider.type-1 .text {
    font-size: 15px;
  }
  .slider-buttons .outer:nth-child(1) .inner {
    margin-right: 50px;
  }
  .slider-buttons .outer:nth-child(2) .inner {
    margin-left: 50px;
  }
  .slider-buttons:before {
    width: 100px;
    height: 115px;
    background-size: 100px;
    top: -26px;
  }
  .intro-title {
    padding-top: 20px;
    font-size: 24px;
    line-height: 30px;
  }
  .intro-title strong {
    font-size: 36px;
    line-height: 30px;
  }
  .intro-text {
    font-size: 16px;
  }
  .bigmap-description h2 {
    font-size: 24px;
  }
  .home-about .bar {
    padding: 20px 0;
  }
  .home-about .home-about-block,
  .home-about .home-about-block + .home-about-block {
    width: 100%;
    padding: 10px 20px 10px 20px;
  }
  .home-about .home-about-block h2,
  .home-about .home-about-block + .home-about-block h2 {
    font-size: 16px;
  }
  .home-about .home-about-block h2 i,
  .home-about .home-about-block + .home-about-block h2 i {
    font-size: 21px;
    margin-right: 5px;
  }
  .home-about .home-about-block p,
  .home-about .home-about-block + .home-about-block p {
    line-height: 20px;
    font-size: 14px;
  }
  .popular-games-list .popular-game:hover .flair {
    transform: rotate(-15deg);
  }
  .popular-games-list .popular-game .title {
    line-height: 1em;
    padding: 15px 0;
    left: 0;
  }
  .popular-games-list .popular-game .title strong {
    font-size: 16px;
  }
  .popular-games-list .popular-game .title span {
    font-size: 14px;
  }
  .popular-games-list .popular-game .price {
    font-size: 13px;
  }
  .popular-games-list .popular-game .price strong {
    font-size: 18px;
    top: 0;
    margin: 0;
    display: block;
    font-weight: bold;
  }
  .popular-games-list .popular-game a.orderbutton {
    margin: 10px 5px;
  }
  /*
	.game-list {
		.game {
			width: 98%;
			float: none;
			margin: 12px 0;
			.title {
				margin-top: 19px;
			}
		}
	}
	*/
  .ddos-header .big-heading {
    width: 100%;
    float: none;
  }
  .ddos-header .corero {
    position: relative !important;
    top: auto;
    right: auto;
    left: 50%;
    width: 50%;
    padding-top: 0;
    height: 100px;
    margin-bottom: 20px;
    background-position: center center;
    animation: none;
    transform: translate(-50%);
  }
  .big-heading {
    padding: 30px 0;
  }
  .big-heading.left {
    text-align: center;
  }
  .big-heading .title {
    font-size: 32px;
  }
  .big-heading .subtitle {
    font-size: 16px;
  }
  .big-heading .supertitle {
    font-size: 20px;
  }
  .ddos-feature-bar .feature .icon {
    height: 100px;
    background-size: 130px;
  }
  .ddos-feature-bar .feature:hover .icon {
    background-size: 140px;
  }
  .three-boxes .box {
    width: 80%;
    margin: 20px 10%;
  }
  .game-header .body {
    padding: 20px 10px;
  }
  .game-header .game-name-box {
    padding: 0px 20px;
  }
  .game-header .game-name-box .title {
    font-size: 28px;
  }
  .game-header .game-name-box .subtitle {
    font-size: 16px;
    margin: 10px auto 0 auto;
  }
  .web-hosting-features {
    padding: 40px 0 20px 0;
    width: 98%;
  }
  .web-hosting-features .feature {
    min-height: 100px;
    padding-top: 0;
    margin-bottom: 15px;
  }
  .web-hosting-features .feature .icon {
    position: absolute;
    left: 50px;
    top: 0px;
    width: 70px;
    height: 81px;
  }
  .web-hosting-features .feature .icon:before {
    height: 45px;
  }
  .web-hosting-features .feature .content {
    padding-top: 0px;
  }
  .web-hosting-features .feature .title {
    font-size: 18px;
  }
  .web-hosting-features .feature .text {
    margin: 5px;
    line-height: 1.3em;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.7);
  }
  .web-hosting-features .feature:nth-child(odd) .icon {
    left: 10px;
  }
  .web-hosting-features .feature:nth-child(odd) .icon:before {
    width: 10px;
    left: -10px;
  }
  .web-hosting-features .feature:nth-child(odd) .content {
    padding-left: 100px;
  }
  .web-hosting-features .feature:nth-child(even) .icon {
    left: 10px;
  }
  .web-hosting-features .feature:nth-child(even) .icon:before {
    width: 10px;
    left: -10px;
  }
  .web-hosting-features .feature:nth-child(even) .content {
    padding-left: 100px;
  }
}
@media only screen and (max-width: 720px) {
  .ddos-armor {
    min-height: 500px;
    background-size: 200px;
    background-position: 100% 100%;
  }
  .ddos-armor .ddos-graph {
    position: absolute;
    transform: scale(0.66);
    top: 100px;
  }
  .ddos-armor .title-area {
    text-align: center;
    padding: 30px 0 0 0;
  }
  .ddos-armor .title-area .title {
    transform-origin: center center;
    text-align: center;
    float: none;
    max-width: 80%;
    margin: 0 auto;
    font-size: 32px;
  }
  .ddos-armor .title-area .subtitle {
    font-size: 16px;
    margin: auto;
    text-align: center;
    float: none;
    max-width: 100%;
  }
  .option-bar {
    margin: 0 0 30px 0;
    padding: 10px;
  }
  .option-bar a {
    display: block;
    line-height: 40px;
    padding: 0 20px 2px 20px;
  }
  .option-bar a.selected {
    border-bottom: 2px solid #ccc;
  }
  .text-page {
    margin: 10px auto;
  }
  .text-page h1 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 639px) {
  .home-services .service {
    width: 100%;
  }
  .bigmap-back {
    width: 300px;
  }
  .bigmap {
    height: 110px;
    background-size: 300px;
  }
  .bigmap-pins {
    transform: scale(0.2);
  }
  .bigmap-pins .pin {
    transform: scale(2);
  }
  .bigmap-pins .pin span {
    display: none !important;
  }
  .bigmap-description .block {
    margin-top: 20px;
  }
  .home-about h1 {
    font-size: 26px;
    line-height: 26px;
  }
  .home-about .desc {
    font-size: 14px;
    margin: 8px auto;
    line-height: 20px;
  }
  .home-about .bar {
    padding: 10px 0;
  }
  .home-about .home-about-block {
    width: 100%;
    padding: 0 20px 0 20px;
  }
  .home-about .home-about-block h2 {
    font-size: 15px;
  }
  .home-about .home-about-block h2 i {
    font-size: 21px;
    margin-right: 5px;
  }
  .home-about .home-about-block p {
    line-height: 20px;
    font-size: 14px;
  }
  .home-about a.bigbutton {
    margin: 0 0 20px 0;
  }
  .footer-partners .partners {
    width: 100%;
  }
  .footer-partners .partners-1,
  .footer-partners .partners-2 {
    background-position: center center;
  }
  .gameservers-header .first-part {
    float: none;
    width: 100%;
    text-align: center;
    padding: 20px 20px;
  }
  .gameservers-header .second-part {
    position: relative;
    width: 100%;
  }
  .gameservers-header .second-part:before,
  .gameservers-header .second-part:after {
    content: " ";
    display: table;
    visibility: hidden;
  }
  .gameservers-header .second-part:after {
    clear: both;
  }
  .popular-games-list .popular-game {
    width: 48%;
  }
  .game-list-back .game-list {
    padding: 20px 0 50px;
  }
  /*
	.game-list {
		.game {
			padding-left: 50px;
			.image {
				transform: scale(0.66);
				left:-5px;
			}
			.title {
				margin-top: 20px;
				.f16;
				line-height: 16px;
			}
			.price {
				padding: 3px 5px;
				.normal;
				color:#666;
				.f12;
				strong {
					.f16;
				}
			}
			.buttonset {
				a {
					padding: 5px 10px;
					.f12;
				}
			}
			
			&:before, &:after {
				left:-10px;
				border-left: 100px solid #c4c6ca;
			}
			&:before {
				border-bottom: 50px solid transparent;
				top: -11px;
			}
			&:after {
				border-top: 50px solid transparent;
				bottom: -11px;
			}
		}
	}
	*/
  .ddos-feature-bar .feature {
    width: 96%;
  }
  .game-list .game {
    padding-left: 32px;
  }
  .game-list .game .title {
    font-size: 16px;
    margin-top: 3px;
  }
  .game-list .game .price {
    display: none;
    right: 0;
    width: 90%;
    text-align: right;
  }
  .game-list .game a.more-button {
    width: 30px;
  }
  .game-list .game a.more-button:before {
    left: 10px;
    top: 15px;
    width: 10px;
    height: 10px;
  }
  .game-list .game.activated {
    margin-bottom: 100px;
  }
  .game-list .game.activated .buttonset {
    display: block;
    bottom: -83px;
  }
  .game-list .game.activated .price {
    top: 50px;
    display: block;
    background-color: #fff;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff 50%);
    padding: 10px;
  }
  .game-pricing-bar .bar-content {
    width: 100%;
  }
  .game-pricing-bar .box:nth-child(1) {
    width: 50%;
  }
  .game-pricing-bar .box:nth-child(2) {
    width: 50%;
  }
  .game-pricing-bar .box:nth-child(3) {
    width: 100%;
  }
  .game-pricing-bar .box {
    transform: none;
  }
  .game-pricing-bar .box .content {
    transform: none;
  }
  .game-pricing-bar a.big-order-button {
    transform: none;
  }
  .game-pricing-bar a.big-order-button em {
    transform: none;
  }
}
@media only screen and (max-width: 600px) {
  .slider-area .slider {
    text-align: center;
  }
  .slider-area .slider .body {
    padding: 0 10px 80px;
  }
  .slider-area .slider.type-1 .subtitle {
    margin: 20px;
  }
  .slider-area .slider.type-1 a.hugebutton {
    position: relative;
    display: inline-block;
    top: 0;
    right: 0;
    margin: 20px 20px 30px;
  }
  .slider-buttons .outer:nth-child(1) .inner {
    margin-right: 0;
  }
  .slider-buttons .outer:nth-child(2) .inner {
    margin-left: 0;
  }
  .slider-buttons:before {
    display: none;
  }
  .slider-buttons .back {
    background: #000;
  }
  .slider-buttons .inner a {
    height: auto;
    min-height: 80px;
    background: #000;
    padding: 10px 5px 10px;
    text-align: center;
  }
}
@media only screen and (max-width: 570px) {
  .footer-links {
    padding-top: 50px;
  }
  .footer-links ul {
    width: 70%;
    box-sizing: border-box;
  }
  .footer-social a {
    margin: 10px 0;
  }
}
@media only screen and (max-width: 540px) {
  .ddos-armor {
    min-height: 370px;
  }
  .ddos-armor .ddos-graph {
    transform: scale(0.4);
  }
  .ddos-armor .title-area .title {
    font-size: 20px;
  }
}
@media only screen and (max-width: 319px) {
  a.logo {
    margin-left: 10px;
  }
}
